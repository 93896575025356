<template>
  <div class="my_gallery_list_wrap">
    <div class="my_gallery_setting">
        <button class="setting_btn" @click="movePage('/gallery')">{{ $t('collection_setting') }}</button><br>
    </div>
    <div class="my_gallery_list_box" v-for="i in 9" :key="i" @click="movePage(`/gallery/${index}/edit`)">
      <div class="my_gallery_list_img"></div>
      <div class="my_gallery_list_title">
        <span>고양이 사진관</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemListGalleryMyLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    movePage(url){
      this.$router.push(url);
    }
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
